@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.background-number {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* top: 0; */
  margin: auto;
  font-size: 15rem;
  font-weight: bold;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
}


.double-digit {
  bottom: 60px;
  left: -30px;
  letter-spacing: -20px;
  font-size: 10rem;
}

.back-arrow {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.card-size {
  /* width: 320px; */
  height: 375px;
}


.swiper-slide-shadow {
  background-color: transparent !important;
}

.swiper-container {
  height: 500px;
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* padding: 1rem; */
}

.swiper-slide-active .card-size {
  /* height: 450px; */
  /* transform: scale(1.1); */
}

.swiper-slide .card-size {
  box-shadow: none !important;
  border: 1px solid #dedede;
}

.my-swiper {
  margin-bottom: 2rem;
}

swiper-container {
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  background-color: transparent;
}

swiper-slide {
  padding-block: 1rem;
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transform: scale(.8);
  transition: all 500ms ease;
}

.swiper-slide-active {
  transform: scale(1);

  transition: all 500ms ease;
}

.swiper-slide-prev,
.swiper-slide-next {
  transform: scale(.9);
  transition: all 500ms ease;
}


@media (min-width: 500px) {
  .background-number {
    font-size: 22rem;
    bottom: -60px;
  }

  .double-digit {
    font-size: 15rem;
    bottom: 30px;
    left: -30px;

  }
}